import { IInvoiceReportsArgs } from "src/services/InvoiceReport/models";
import { IReportFormValues } from "../models/formValues";

interface IProps {
  values: Partial<IReportFormValues>;
  offset: number;
  limit: number;
  allRequestId?: number;
  attachmentId?: number;
  invoiceId?: number;
}
export const createInvoiceReportReqBody = ({
  values,
  offset,
  limit,
  allRequestId,
  attachmentId,
  invoiceId,
}: IProps) => {
  const reqBody: Partial<IInvoiceReportsArgs> = {
    allRequestId,
    attachmentId,
    invoiceId,
    page: {
      offset,
      limit,
    },
    shipId: values.ShipId,
    recordFromDate: values.createdDate ? values.createdDate[0] : undefined,
    recordToDate: values.createdDate ? values.createdDate[1] : undefined,
    requesterId: values.RequesterId,
    paymentCurrencyId: values.PaymentCurrencyId,
    zipCode: values.ZipCode,
    requesterFromDate: values.RequesterDate
      ? values.RequesterDate[0]
      : undefined,
    requesterToDate: values.RequesterDate ? values.RequesterDate[1] : undefined,
    dueFromDate: values.DueDate ? values.DueDate[0] : undefined,
    dueToDate: values.DueDate ? values.DueDate[1] : undefined,
    attachmentCode: values.AttachmentCode,
    invoiceType: values.InvoiceType,
    invoiceNumber: values.InvoiceNumber,
    shipManagementInvoiceNumber: values.ShipManagementInvoiceNumber,
    shipManagementInvoiceFromDate: values.RequesterInvoiceDate
      ? values.RequesterInvoiceDate[0]
      : undefined,
    shipManagementInvoiceToDate: values.RequesterInvoiceDate
      ? values.RequesterInvoiceDate[1]
      : undefined,
    supplierId: values.SupplierId,
    supplierInvoiceNumber: values.supplierInvoiceNumber,
    supplierInvoiceFromDate: values.SupplierInvoiceDate
      ? values.SupplierInvoiceDate[0]
      : undefined,
    supplierInvoiceToDate: values.SupplierInvoiceDate
      ? values.SupplierInvoiceDate[1]
      : undefined,
    invoiceCurrencyId: values.InvoiceCurrencyId,
    supplierTotalFromAmount: values.SupplierTotalFromAmount
      ? +values.SupplierTotalFromAmount
      : undefined,
    supplierTotalToAmount: values.SupplierTotalToAmount
      ? +values.SupplierTotalToAmount
      : undefined,
    ourFromDate: values.OurDate ? values.OurDate[0] : undefined,
    ourToDate: values.OurDate ? values.OurDate[1] : undefined,
    shipManagementTotalFromAmount: values.ShipManagementTotalFromAmount
      ? +values.ShipManagementTotalFromAmount
      : undefined,
    shipManagementTotalToAmount: values.ShipManagementTotalToAmount
      ? +values.ShipManagementTotalToAmount
      : undefined,
    categoryCode: values.categoryCode,
    categoryIds: values.categoryIds,
    quantityFrom: values.quantityFrom ? +values.quantityFrom : undefined,
    quantityTo: values.quantityTo ? +values.quantityTo : undefined,
    unitPriceFrom: values.unitPriceFrom ? +values.unitPriceFrom : undefined,
    unitPriceTo: values.unitPriceTo ? +values.unitPriceTo : undefined,
    amountFrom: values.amountFrom ? +values.amountFrom : undefined,
    amountTo: values.amountTo ? +values.amountTo : undefined,
    // voyageNo: values.voyageNo,
    // voyageId: values.voyageId,
  };
  return reqBody;
};
